<!--  -->
<template>
  <div class="banner">
    <div class="inner">
      <div class="menu-bar isPc">
        <div class="view">
          <div class="category">
            <ul class="category-option">
              <li class="cat-item top-cat">
                <div class="sub-cat clearfix">111</div>
                <div class="li">
                  <img src="@/assets/img/icon01.png" />
                  <router-link class="txt" to>热门API</router-link>
                </div>
              </li>
              <li class="cat-item">
                <div class="sub-cat clearfix">222</div>
                <div class="li">
                  <img src="@/assets/img/icon03.png" />
                  <router-link class="txt" to>大厂接口</router-link>
                </div>
              </li>
              <li class="cat-item">
                <div class="sub-cat clearfix">333</div>
                <div class="li">
                  <img src="@/assets/img/icon04.png" />
                  <router-link class="txt" to>人工智能</router-link>
                </div>
              </li>
              <li class="cat-item">
                <div class="sub-cat clearfix">444</div>
                <div class="li">
                  <img src="@/assets/img/icon05.png" />
                  <router-link class="txt" to>虚拟充值</router-link>
                </div>
              </li>
              <li class="cat-item">
                <div class="sub-cat clearfix">555</div>
                <div class="li">
                  <img src="@/assets/img/icon06.png" />
                  <router-link class="txt" to>生活服务</router-link>
                </div>
              </li>
              <li class="cat-item">
                <div class="sub-cat clearfix">666</div>
                <div class="li">
                  <img src="@/assets/img/icon07.png" />
                  <router-link class="txt" to>交通地理</router-link>
                </div>
              </li>
              <li class="cat-item top-cat">
                <div class="sub-cat clearfix">666</div>
                <div class="li">
                  <img src="@/assets/img/icon08.png" />
                  <router-link class="txt" to>IT技术</router-link>
                </div>
              </li>
              <li class="cat-item">
                <div class="sub-cat clearfix">777</div>
                <div class="li">
                  <img src="@/assets/img/icon09.png" />
                  <router-link class="txt" to>企业管理</router-link>
                </div>
              </li>
              <li class="cat-item">
                <div class="sub-cat clearfix">888</div>
                <div class="li">
                  <img src="@/assets/img/icon10.png" />
                  <router-link class="txt" to>金融商业</router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--轮播-->
      <div class="swiperBox">
        <swiper>
          <swiper-slider>
            <img src="@/assets/img/banner1.png" alt />
          </swiper-slider>
          <swiper-slider>
            <img src="@/assets/img/banner2.png" alt />
          </swiper-slider>
          <swiper-slider>
            <img src="@/assets/img/banner3.png" alt />
          </swiper-slider>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import swiper from "@/components/common/swiper/index.vue";
import swiperSlider from "@/components/common/swiper-slider/index.vue";
export default {
  data () {
    return {
    };
  },

  components: {
    swiper,
    swiperSlider
  },
  mounted () {
  },

  computed: {},

  methods: {

  }
}

</script>
<style lang='scss' scoped>
.banner {
  width: 100%;
  height: 433px;
  background: url('../../../assets/img/banner_bg.png');
  .inner {
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
  .category-option {
    margin-top: -1px;
    width: 226px;
    padding: 6px 0;
    background: #fff;
    // rgba(00, 00, 00, 0.8)
    position: absolute;
    z-index: 101;
    display: block;
  }
  .category-option .cat-item {
    height: 45px;
    line-height: 45px;
    vertical-align: top;
    transition: 0.2s ease-in-out;
    // position: relative;
  }
  .category-option .cat-item .sub-cat {
    overflow: hidden;
    display: none;
    width: 800px;
    height: 417px;
    padding: 9px 30px 25px 0;
    left: 226px;
    top: 0;
    background: rgba(255, 255, 255, 0.95);
    z-index: 99;
    position: absolute;
    line-height: 20px;
    box-sizing: border-box;
  }
  .category-option .cat-item .li {
    padding: 0 20px 0 55px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 100;
    box-sizing: border-box;
  }
  .category-option .cat-item .li::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 45px;
    display: block;
    width: 8px;
    height: 12px;
    transform: translateY(-50%);
    background: url('../../../assets/img/arrow.png');
    background-size: 100% 100%;
  }
  .category-option .cat-item img {
    margin-right: 15px;
  }

  .category-option .cat-item .txt {
    color: #333;
    margin-right: 10px;
    font-size: 13px;
  }

  .category-option .cat-item.top-cat .txt {
    color: #333;
    font-size: 14px;
  }

  .category-option .cat-item .li:hover {
    box-shadow: -8px 0px 8px rgb(0 0 0 / 10%);
    background: #fff;
    margin-right: -1px;
  }

  .category-option .cat-item:hover .sub-cat {
    display: block;
    opacity: 1;
    padding-left: 10px;
    animation: subcat 0.3s ease-in-out;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  }

  @keyframes subcat {
    from {
      padding-left: 0;
    }
    to {
      padding-left: 10px;
    }
  }

  .fadeInR {
    animation-name: fadeInR;
    animation-duration: 1.5s;
    animation-timing-function: linear;
  }
  @keyframes fadeInR {
    from {
      left: -20px;
    }
    to {
      opacity: 1;
      filter: alpha(opacity=100);
      left: 0;
    }
  }
  .scroll-btn {
    position: absolute;
    overflow: hidden;
    bottom: 30px;
    z-index: 3;
    width: 100%;
    text-align: center;
  }
  .scroll-btn span {
    display: inline-block;
    width: 30px;
    height: 5px;
    margin: 0 1px;
    cursor: pointer;
    background: #fff;
  }
  .scroll-btn span.current {
    background: #333;
  }
}
.swiperBox {
  padding-top: 16px;
  text-align: center;
  margin-left: 246px;
  height: 433px;
  box-sizing: border-box;
  // width: 746px;
  // width: calc(100% - 226px);
}
@media screen and(max-width:996px) {
  .banner {
    height: auto;
    background: none;
  }
  .swiperBox {
    padding: 0;
    width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
}
</style>