<!--  -->
<template>
  <div class="nav">
    <div class="inner layout-flex">
      <div class="category layout-flex-all">
        <img src="@/assets/img/picnav.png" alt class="isMobile" />
        云市场分类
        <img src="@/assets/img/icon02.png" class="isPc" />
      </div>
      <div class="navList layout-flex">
        <router-link
          :class="['item',{'active':item.path==$route.path},'isPc']"
          v-for="(item,index) in navData"
          :key="index"
          :to="item.path"
        >{{item.name}}</router-link>
        <div class="menu-opener isMobile" @click="menuHandle">
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div :class="['menu-list',{'activemenu':show}]">
      <div class="close" @click="menuHandle">
        <span class="iconfont pdxcha icon"></span>
      </div>
      <div class="data">
        <ul>
          <li v-for="(item,index) in navData" :key="index">
            <router-link to>{{item.name}}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      current: 0,
      show: false,
      navData: [{ name: "首页", path: '/' }, { name: "API", path: "/Interface/list" }]
    };
  },

  components: {},

  computed: {},

  methods: {
    menuHandle () {
      if (this.show) {
        this.show = false
      } else {
        this.show = true
      }

    }
  }
}

</script>
<style lang='scss' scoped>
.menu-list {
  width: 0vw;
  height: 0vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: #fff;
  opacity: 0;
  overflow: hidden;
  transition: height 0.5s;
  .close {
    position: absolute;
    right: 25px;
    top: 15px;
    .icon {
      font-size: 24px;
      color: #0078d7;
      font-weight: bold;
    }
  }
  .data {
    padding: 60px 0;
    ul {
      li {
        text-align: center;
        margin-bottom: 20px;
        color: #333;
      }
    }
  }
}
.activemenu {
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: height 0.5s;
}
.nav {
  width: 100%;
  border-bottom: 1px solid #e6ecee;
  .inner {
    max-width: 1200px;
    margin: 0 auto;
    .category {
      margin-right: 20px;
      padding: 0 12px 0 28px;
      width: 186px;
      height: 52px;
      color: #fff;
      background: #2468f2;
      border-radius: 1px;
    }
    .navList {
      width: calc(100% - 250px);
      .item {
        padding: 0 20px;
        color: #666;
        cursor: pointer;
      }
      .active {
        color: $theme;
        position: relative;
        &::after {
          content: '';
          display: block;
          height: 2px;
          width: 30px;
          background: $theme;
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .menu-opener {
        .line {
          width: 26px;
          height: 3px;
          background: #0078d7;
          position: relative;
          cursor: pointer;
          &::after {
            content: '';
            display: block;
            width: 26px;
            height: 3px;
            position: absolute;
            left: 0;
            top: -8px;
            background: #0078d7;
          }
          &::before {
            content: '';
            display: block;
            width: 26px;
            height: 3px;
            position: absolute;
            left: 0;
            top: 8px;
            background: #0078d7;
          }
        }
      }
    }
  }
}
@media screen and(max-width:996px) {
  .nav {
    .inner {
      .navList {
        height: 52px;
        padding-right: 10px;
        width: calc(100% - 100px);
        justify-content: flex-end;
        .menu-opener {
          height: 100%;
          display: flex !important;
          align-items: center;
          cursor: pointer;
        }
      }
      .category {
        height: 52px;
        max-width: 105px;
        color: #333;
        background: #fff;
        cursor: pointer;
        img {
          width: 16px;
        }
      }
    }
  }
}
</style>