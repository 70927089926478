<!--  -->
<template>
  <div>
    <div>
      <pNav></pNav>
      <pBanner></pBanner>
    </div>
    <div class="content">
      <div class="inner">
        <h2 class="header-title">热门API推荐</h2>
        <div class="item isPc">
          <div class="left">
            <div class="img layout-flex">
              <img src="@/assets/img/img.png" alt />
            </div>
            <div class="txt">
              <div class="tit">全国快递</div>
              <p class="overLine2">支持顺丰！支持国内外1500+快递物流公司订单查询，自动识别单号，覆盖范围广，接口稳定</p>
              <ul>
                <li>质量可靠</li>
                <li>支持面广，稳定性强</li>
              </ul>
              <btn type="primary" buttonname="热销产品" :buttonstyle="style"></btn>
              <p class="tips">低至0.001元/每次</p>
            </div>
          </div>
          <div class="right layout-flex">
            <div class="card" v-for="(item,index) in 5" :key="index" @click="jump('api/apiList')">
              <div class="img layout-flex">
                <img src="@/assets/img/img01.png" alt />
              </div>
              <div class="txt">
                <div class="tit">全国快递</div>
                <p class="overLine2">支持顺丰！支持国内外1500+快递物流公司订单查询，自动识别单号，覆盖范围广，接口稳定</p>
                <p class="tips">低至0.001元/每次</p>
              </div>
            </div>
          </div>
        </div>
        <div class="item isMobile">
          <div class="card" v-for="(item,index) in 5" :key="index" @click="jump('api/apiList')">
            <div class="img layout-flex">
              <img src="@/assets/img/img01.png" alt />
            </div>
            <div class="txt">
              <div class="tit">全国快递</div>
              <p class="overLine2">支持顺丰！支持国内外1500+快递物流公司订单查询，自动识别单号，覆盖范围广，接口稳定</p>
              <p class="tips">低至0.001元/每次</p>
            </div>
          </div>
        </div>
        <h2 class="header-title">API类目推荐</h2>
        <div class="item02">
          <!--  -->
          <div class="line" v-for="(item,index) in classification" :key="index">
            <div class="card-template">
              <!-- {{item.name}} -->
              <div class="tit">{{item.name}}</div>
              <p v-html="item.info"></p>
              <div class="btn">查看更多</div>
            </div>
            <div class="card-item layout-flex">
              <!--  v-for="(items,indexs) in item.content" :key="indexs" -->
              <div class="card" v-for="(items,indexs) in item.content" :key="indexs">
                <div class="img layout-flex">
                  <img src="@/assets/img/img01.png" alt />
                </div>
                <div class="txt">
                  <!--  -->
                  <div class="tit">{{items.name}}</div>
                  <!--   -->
                  <p class="overLine2" v-html="items.info"></p>
                  <p class="tips">低至0.001元/每次</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCommodityClassification, getCommodityPage } from "@/api/frontApi"
import pNav from "@/components/pc/p-nav/p-nav"
import pBanner from "@/components/pc/p-banner/p-banner"
export default {
  data () {
    return {
      classification: [],
      Commodity: [],
      style: {
        'width': '74px',
        'height': '24px',
        'font-size': '14px',
        'border-radius': "20px"
      }
    };
  },
  created () {
    getCommodityClassification().then(res => {
      this.classification = res
      this.classification.forEach((item, index) => {
        getCommodityPage({ currentPage: 1, size: 4, type: item.id }).then(res => {
          this.$set(this.classification[index], 'content', res.records.slice(0, 4))
        }
        )
      })

    })
  },
  mounted () {
  },
  components: {
    pNav,
    pBanner,
  },

  computed: {},

  methods: {
    jump (path) {
      this.$router.push('/' + path)
    }
  }
}

</script>
<style lang='scss' scoped>
.card {
  padding: 12px 0;
  background: #fff;
  box-sizing: border-box;
  cursor: pointer;
  .img {
    margin: 0 15px 10px;
    width: 96px;
    height: 96px;
    background: #f2f5f9;
    border-radius: 50%;
    justify-content: center;
    img {
      width: 57px;
      height: 57px;
    }
  }
  .txt {
    padding: 0 15px;
    .tit {
      font-size: 16px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
      font-size: 12px;
      color: #666;
      line-height: 1.5;
    }
    .tips {
      margin-top: 10px;
      font-size: 16px;
      color: #ff6a00;
    }
  }
  &:hover {
    box-shadow: 1px 5px 10px 3px #efefef;
  }
}
.content {
  padding: 60px 0;
  width: 100%;
  background: #f3f7fa;
  .inner {
    max-width: 1200px;
    margin: 0 auto;
    .header-title {
      margin-bottom: 50px;
      text-align: center;
      color: #333;
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 1px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -20px;
        display: block;
        width: 42px;
        height: 5px;
        transform: translateX(-50%);
        background: #2468f2;
      }
    }
    .item {
      margin-bottom: 80px;
      display: flex;
      justify-content: space-between;
      .left {
        width: 282px;
        background: #fff;
        cursor: pointer;
        .img {
          margin: 15px;
          width: 250px;
          height: 206px;
          background: #f2f5f9;
          justify-content: center;
          img {
            width: 150px;
            height: 150px;
          }
        }
        .txt {
          padding: 0 15px 35px;
          .tit {
            font-size: 16px;
            margin-bottom: 10px;
          }
          p {
            margin-bottom: 10px;
            font-size: 12px;
            color: #666;
            line-height: 1.5;
          }
          ul {
            font-size: 14px;
            margin-bottom: 30px;
            li {
              padding-left: 20px;
              line-height: 1.8;
              position: relative;
              &:after {
                position: absolute;
                top: 50%;
                left: 0;
                content: '';
                transform: translateY(-50%);
                display: block;
                width: 4px;
                height: 4px;
                border: 1px solid $theme;
                border-radius: 50%;
              }
            }
          }
          .tips {
            margin-top: 10px;
            font-size: 16px;
            color: #ff6a00;
          }
        }
        &:hover {
          box-shadow: 1px 5px 10px 3px #efefef;
        }
      }
      .right {
        width: calc(100% - 300px);
        flex-wrap: wrap;
        align-content: space-between;
        .card {
          width: calc(33% - 16px);
          // &:not(:nth-of-type(3n)) {
          margin: 0 8px;
          // }
        }
      }
    }
    .item02 {
      .line {
        margin-bottom: 20px;
        width: 100%;
        // height: 322px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .card-template {
          padding: 30px;
          width: 244px;
          height: 322px;
          // height: 100%;
          color: #fff;
          background: #f1b27d;
          background-image: url('../../../../assets/img/line-bg.png');
          box-sizing: border-box;
          .tit {
            padding-bottom: 60px;
            font-size: 18px;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              bottom: 30px;
              left: 0;
              width: 40px;
              height: 4px;
              background: #fff;
            }
          }
          p {
            margin-bottom: 20px;
            font-size: 14px;
          }
          .btn {
            width: 100px;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            border: 1px solid #fff;
          }
        }
        .card-item {
          height: 322px;
          width: calc(100% - 264px);
          .card {
            padding: 30px 10px;
            width: 225px;
            height: 100%;
            background: #fff;
            &:not(:last-child) {
              margin-right: 11px;
            }
            .img {
              margin: 0 auto 20px;
            }
            .tit {
              margin-bottom: 20px;
              text-align: center;
            }
            .tips {
              margin-top: 40px;
              text-align: center;
            }
          }
        }
        &:nth-of-type(2) {
          .card-template {
            background-color: #dc88e6;
          }
        }
        &:nth-of-type(3) {
          .card-template {
            background-color: #f98775;
          }
        }
        &:nth-of-type(4) {
          .card-template {
            background-color: #77d3a4;
          }
        }
        &:nth-of-type(5) {
          .card-template {
            background-color: #ba72fc;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 996px) {
  .content {
    padding-top: 30px;
    .inner {
      padding: 0 20px;
      .header-title {
        margin-bottom: 25px;
        font-size: 18px;
        &::after {
          display: none;
        }
      }
      .isMobile {
        width: 100%;
        display: flex !important;
        flex-wrap: wrap;
        .card {
          width: 48%;
          margin-bottom: 10px;
          .item {
          }
        }
      }
      .item02 {
        .line {
          width: 100%;
          flex-wrap: wrap;
          .card-template {
            width: 100%;
          }
          .card-item {
            width: 100%;
            .card {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>